<template>
    <div class="results">
        <h2>Your reaction time is {{ score }}ms</h2>
        <h3>Level: {{ level }}</h3>
    </div>
</template>

<script>
export default {
    props: ['score'],
    computed: {
        level() {
            if (this.score < 250) {
                return 'the ninja 👍'
            } else if (this.score < 400) {
                return 'its okay  👍'
            } else if (this.score < 500) {
                return 'vilam köröketveram '
            } else if (this.score < 600) {
                return 'amikor megszületel Anyad 3x dobot fel de 2 kapot el'
            }
            else {
                return 'bullshit 👎'
            }
        }
    }
}
</script>

<style>
.results {
    font-size: 2em;
    color: #0faf87;
}

</style>