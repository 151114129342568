<template>
  <h1>Karateka and Fencer Reaction Timer</h1>
  <button @click="start" :disabled="isPlaying">Start</button>
  <p v-if="!score && !isPlaying">Click Start and, wait for the green block to appear, then click it as fast as you can!</p>
  <Block v-if="isPlaying" :delay="delay" @reaction-time="endGame"/>
  <Results v-if="score" :score="score"/>
</template>

<script>
import Block from './components/Block.vue'
import Results from './components/Results.vue'

export default {
  name: 'App',
  components: { Block, Results },
  data() {
    return {
      isPlaying: false,
      delay: null,
      score: null,
    }
  },
  methods: {
    start() {
      this.isPlaying = true
      this.delay = Math.random() * 5000 + 2000
      this.score = null
    },
    endGame(reactionTime) {
      this.score = reactionTime
      this.isPlaying = false
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #444;
  margin-top: 60px;
}
button {
  font-size: 2em;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #0faf87;
  background-color: white;
  color: #0faf87;
  cursor: pointer;
  margin-top:20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.65);
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  box-shadow: none;
}
@media screen and (max-width: 480px) {
  #app {
    margin-top: 0;
  }
  .block {
    padding: 60px 0;
  }
  .results {
    font-size: unset;
  }
}
</style>
